import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import validate from 'validate.js'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { makeStyles } from '@material-ui/styles'
import {
  Box,
  TextField,
  Button,
  Checkbox,
  Typography,
  Link,
  FormHelperText,
  InputAdornment
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import zxcvbn from 'zxcvbn'
import PasswordStrength from 'src/components/PasswordStrength'
import IconButton from '@material-ui/core/IconButton'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
  },
  fields: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    'flex-flow': 'column wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    marginTop: theme.spacing(4),
    width: '100%'
  },
  policy: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  checkboxContainer: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0)
  },
  helperText: {
    paddingTop: 0,
    marginTop: -theme.spacing(1.3),
    paddingLeft: theme.spacing(3.5)
  }
}))

function CompleteForm ({ setPassword = true, action, termsUrl, vatId, companyName = null, companyExists }) {
  const classes = useStyles()
  const privacySupplierUrl = 'https://www.plusadvance.com/documenti/Informativa-Privacy-PlusAdvance-Fornitori.pdf'
  const { t } = useTranslation('completeForm')
  const [formState, setFormState] = useState({
    isValid: false,
    passwordScore: 0,
    values: {
      acceptTerms: false,
      acceptPrivacyPolicy: false,
      acceptMarketing: false
    },
    touched: {},
    errors: {}
  })
  const getPasswordError = () => {
    const password = formState.values.password
    if (formState && formState.values && formState.values.password) {
      const lowerCaseLetters = /[a-z]/g
      if (!password.match(lowerCaseLetters)) {
        return t('schema.password.minusc')
      }
      const upperCaseLetters = /[A-Z]/g
      if (!password.match(upperCaseLetters)) {
        return t('schema.password.maiusc')
      }
      const numbers = /[0-9]/g
      if (!password.match(numbers)) {
        return t('schema.password.numbers')
      }
      const notWordChars = /[^a-zA-Z0-9]/g
      if (!password.match(notWordChars)) {
        return t('schema.password.symbols')
      }
      return t('schema.password.notValid')
    } else {
      return t('schema.password.format')
    }
  }
  const schema = {
    vatId: {
      presence: { allowEmpty: false, message: t('schema.vatId.presence') },
      length: { is: 11, wrongLength: t('schema.vatId.length') }
    },
    companyName: {
      presence: { allowEmpty: false, message: t('schema.companyName') }
    },
    acceptTerms: {
      presence: { allowEmpty: false, message: t('schema.acceptTerms.presence') },
      checked: {
        message: t('schema.acceptTerms.message')
      }
    }
  }

  if (setPassword) {
    schema.acceptPrivacyPolicy = {
      presence: { allowEmpty: false, message: t('schema.acceptPrivacyPolicy') },
      checked: {
        message: t('schema.acceptPrivacyPolicy')
      }
    }
    schema.password = {
      presence: { allowEmpty: false, message: t('schema.password.presence') },
      length: {
        minimum: 8,
        maximum: 20,
        tooLong: t('schema.password.tooLong'),
        tooShort: t('schema.password.tooShort')
      },
      format: {
        pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/,
        //   message: t('schema.password.format')
        message: getPasswordError()
      }
    }
    schema.confirmPassword = {
      presence: {
        allowEmpty: false,
        message: t('schema.confirmPassword.presence')
      },
      equality: {
        attribute: 'password',
        message: t('schema.confirmPassword.equality')
      }
    }
  }
  const newCompanySchema = {
    ...schema,
    userName: {
      presence: { allowEmpty: false, message: t('newCompanySchema.username') }
    },
    phoneNumber: {
      presence: { allowEmpty: false, message: t('newCompanySchema.phoneNumber') }
    },
    email: {
      presence: { allowEmpty: false, message: t('newCompanySchema.email.presence') },
      email: {
        message: t('newCompanySchema.email.message')
      }
    }
  }

  const [showPassword, setShowPassword] = useState(false)

  const [confirmEnabled, setConfirmEnabled] = useState(true)

  // funzione che aggiorna lo stato della form con i dati inseriti nei campi di input
  const handleChange = (event) => {
    event.persist()

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }))
  }

  // Funzione di submit della form di registrazione autonoma
  const handleSubmit = (event) => {
    event.preventDefault()
    setConfirmEnabled(false)
    const { values } = formState
    action(values)
    setConfirmEnabled(true)
  }

  const hasError = (field) => !!(formState.touched[field] && formState.errors[field])

  useEffect(() => {
    if (companyName && companyExists === 'active') {
      setFormState(prevFormState => ({
        ...prevFormState,
        values: {
          ...prevFormState.values,
          companyName
        }
      }))
    }

    if (vatId) {
      setFormState(prevFormState => ({
        ...prevFormState,
        values: {
          ...prevFormState.values,
          vatId
        }
      }))
    }
  }, [vatId, companyName, companyExists])

  // funzione che segnala o meno un errore su un campo di input, ogni volta che cambia valore
  useEffect(() => {
    const errors = companyExists === 'active'
      ? validate(formState.values, schema, { fullMessages: false })
      : validate(formState.values, newCompanySchema, { fullMessages: false })
    const result = setPassword ? formState.values.password && zxcvbn(formState.values.password) : true
    setFormState(prevFormState => ({
      ...prevFormState,
      isValid: !errors,
      passwordScore: (result && result.score) || 0,
      errors: errors || {}
    }))
    // eslint-disable-next-line
  }, [formState.values, companyExists])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  return (

    <form onSubmit={handleSubmit}>
      <div className={classes.fields}>
        <TextField
          error={hasError('vatId')}
          disabled
          fullWidth
          type='number'
          required
          helperText={hasError('vatId') ? formState.errors.vatId[0] : null}
          label={t('fields.vatId')}
          name='vatId'
          onChange={handleChange}
          value={formState.values.vatId || ''}
          variant='outlined'
        />
        <TextField
          error={hasError('companyName')}
          fullWidth
          disabled={!(companyExists !== 'active')}
          type='text'
          required
          helperText={hasError('companyName') ? formState.errors.companyName[0] : null}
          label={t('fields.companyName')}
          name='companyName'
          onChange={handleChange}
          value={formState.values.companyName || ''}
          variant='outlined'
        />
        {companyExists !== 'active'
          ? (
            <>
              <TextField
                error={hasError('userName')}
                fullWidth
                type='text'
                required
                helperText={hasError('userName') ? formState.errors.userName[0] : null}
                label={t('fields.userName')}
                name='userName'
                onChange={handleChange}
                value={formState.values.userName || ''}
                variant='outlined'
              />
              <TextField
                error={hasError('phoneNumber')}
                fullWidth
                type='number'
                required
                helperText={hasError('phoneNumber') ? formState.errors.phoneNumber[0] : null}
                label={t('fields.phoneNumber')}
                name='phoneNumber'
                onChange={handleChange}
                value={formState.values.phoneNumber || ''}
                variant='outlined'
              />
              <TextField
                error={hasError('email')}
                fullWidth
                type='text'
                required
                helperText={hasError('email') ? formState.errors.email[0] : null}
                label={t('fields.email')}
                name='email'
                onChange={handleChange}
                value={formState.values.email || ''}
                variant='outlined'
              />
            </>
          ) : null}
        {
          setPassword &&
            <>
              <TextField
                error={hasError('password')}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                required
                helperText={hasError('password') ? formState.errors.password[0] : null}
                label={t('fields.password')}
                name='password'
                onChange={handleChange}
                value={formState.values.password || ''}
                variant='outlined'
                InputProps={{
                  endAdornment: (
                    <PasswordStrength
                      password={formState.values.password}
                      passwordScore={formState.passwordScore}
                    />
                  ),
                  startAdornment: (
                    <InputAdornment position='start'>
                      <IconButton
                        tabIndex='-1'
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                error={hasError('confirmPassword')}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                required
                helperText={hasError('confirmPassword') ? formState.errors.confirmPassword[0] : null}
                label={t('fields.confirmPassword')}
                name='confirmPassword'
                onChange={handleChange}
                value={formState.values.confirmPassword || ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <IconButton
                        tabIndex='-1'
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                variant='outlined'
              />
            </>
        }
        <div className={classes.checkboxContainer}>
          <div className={classes.policy}>
            <Checkbox
              checked={formState.values.acceptTerms || false}
              className={classes.policyCheckbox}
              color='primary'
              name='acceptTerms'
              onChange={handleChange}
            />
            <Typography
              color='textSecondary'
              variant='body1'
            >
              {t('fields.read')}
              {' '}
              <Link
                color='primary'
                target='_blank'
                href={termsUrl}
                underline='always'
                variant='h6'
              >
                {t('fields.termsAndConditions')}
              </Link>
            </Typography>
          </div>
          {!formState.values.acceptTerms && (
            <FormHelperText className={classes.helperText} error>{t('schema.acceptTerms.message')}</FormHelperText>
          )}
        </div>
        {
          setPassword &&
            <>
              <div className={classes.checkboxContainer}>
                <div className={classes.policy}>
                  <Checkbox
                    checked={formState.values.acceptPrivacyPolicy || false}
                    className={classes.policyCheckbox}
                    color='primary'
                    name='acceptPrivacyPolicy'
                    onChange={handleChange}
                  />
                  <Typography
                    color='textSecondary'
                    variant='body1'
                  >
                    {t('fields.acceptPrivacyPolicy')}
                    {' '}
                    <Link
                      color='primary'
                      target='_blank'
                      href={privacySupplierUrl}
                      underline='always'
                      variant='h6'
                    >
                      {t('fields.privacyPolicyLink')}
                    </Link>
                  </Typography>
                </div>
                {
                  !formState.values.acceptPrivacyPolicy && <FormHelperText className={classes.helperText} error>{t('schema.acceptPrivacyPolicy')}</FormHelperText>
                }
              </div>
              <div className={classes.checkboxContainer}>
                <div className={classes.policy}>
                  <Checkbox
                    checked={formState.values.acceptMarketing || false}
                    className={classes.policyCheckbox}
                    color='primary'
                    name='acceptMarketing'
                    onChange={handleChange}
                  />
                  <Typography
                    color='textSecondary'
                    variant='body1'
                  >
                    {t('fields.acceptMarketing')}
                  </Typography>
                </div>
              </div>
            </>
        }
      </div>
      <Box display='flex' alignItems='center'>
        <Button
          className={classes.submitButton}
          color='primary'
          disabled={!formState.isValid || !confirmEnabled}
          size='large'
          type='submit'
          variant='contained'
          endIcon={<ExitToAppIcon />}
        >
          {t('buttons.register')}
        </Button>
      </Box>
    </form>
  )
}

CompleteForm.propTypes = {
  action: PropTypes.func.isRequired,
  vatId: PropTypes.string,
  companyName: PropTypes.string,
  companyExists: PropTypes.string.isRequired
}

export default CompleteForm
