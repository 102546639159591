import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
import { makeStyles } from '@material-ui/styles'
import { CardContent, Typography, Card, Box, CircularProgress, Backdrop } from '@material-ui/core'
import Page from 'src/components/Page'
import { red } from '@material-ui/core/colors'
import api from 'src/utils/api'
import Message from 'src/components/Message'
import RetryForm from './RetryForm'
import CompleteForm from './CompleteForm'
import { useTranslation } from 'react-i18next'
import ChangeLanguageButton from 'src/components/ChangeLanguageButton'

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(6, 2)
  },
  card: {
    [theme.breakpoints.up('md')]: {
      width: theme.breakpoints.values.sm
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  cardHeader: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  cardHeaderTitle: {
    color: theme.palette.common.white,
    textTransform: 'uppercase'
  },
  cardHeaderText: {
    color: red[100],
    transition: 'color 0.2s',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.white,
      transition: 'color 0.2s'
    }
  },
  content: {
    padding: theme.spacing(2, 4, 3, 4),
    textAlign: 'center'
  },
  backdrop: {
    zIndex: 900
  },
  icon: {
    height: 'auto',
    width: '50%'
  }
}))

function ActivateUser () {
  const termsUrl = 'https://www.plusadvance.com/documenti/2019_07_04_CONTRATTO_Fornitore.pdf'
  const params = useParams()
  const classes = useStyles()
  const history = useHistory()

  const { t } = useTranslation('activateUser')

  const { activateToken: token, email } = params

  // Controllo se sono nella pagina di verifica della pec
  const isPec = history.location.pathname.includes('pec/')

  const [errorCode, setErrorCode] = useState(null)
  const [loaderStatus, setLoaderStatus] = useState(false)
  const [tries, setTries] = useState(0)
  const [company, setCompany] = useState({ vatId: '', name: '', status: '' })
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [activeTerms, setActiveTerms] = useState('')
  useEffect(() => {
    // Funzione che verifica la PEC o l'email al click del bottone
    async function verifyEmail () {
      const decodedEmail = decodeURIComponent(email)

      try {
        if (isPec) {
          const body = { token, email: decodedEmail }
          // Verifico la PEC
          const activateResponse = await api.postResource('users', { path: '/onboarding/confirmPEC', body })
          const { code: successCode } = activateResponse
          setErrorCode(successCode)
        } else {
          // Verifico l'email
          const body = { email: decodedEmail, onboardingTokenId: token, retries: tries }
          const activateResponse = await api.postResource('users', { path: '/onboarding/activation', body })
          const { company: responseCompany, code: successCode } = activateResponse
          if (responseCompany) {
            const { vatId, name, status } = responseCompany
            setCompany({ vatId, name, status })
          }
          setErrorCode(successCode)
        }
      } catch (e) {
        const errCode = e && e.response && e.response.data && e.response.data.error && e.response.data.error.code
        errCode ? setErrorCode(errCode) : setErrorCode('USER_ACTIVATION_10_1')
      }
    }

    async function verifyToken () {
      try {
        const retrievedActiveTerms = await api.getResource('terms', { path: '?active=true' })
        if (retrievedActiveTerms) {
          setActiveTerms(retrievedActiveTerms[0].termsUrl)
        }
        const tokenVerifyResponse = await api.getResource('users', { path: `/onboarding/validateToken/?token=${token}&email=${email}` })
        const { code: successCode, activation, company: responseCompany } = tokenVerifyResponse
        const { status: activationStatus } = activation
        if (successCode === 'USER_ACTIVATION_OK_7' || successCode === 'USER_ACTIVATION_OK_3') {
          if (activationStatus === 'activation') {
            verifyEmail()
          } else {
            if (responseCompany) {
              const { vatId, name, status } = responseCompany
              setCompany({ vatId, name, status })
            }
            // activation && setActivationId(activation)
            if (activationStatus === 'pendingAdminVerification') {
              setErrorCode('USER_ACTIVATION_7_4')
            } else {
              setErrorCode(successCode)
            }
          }
        }
      } catch (e) {
        const errCode = e && e.response && e.response.data && e.response.data.error && e.response.data.error.code
        console.log(errCode)
        setErrorCode(errCode)
      }
    }
    if (isPec) {
      verifyEmail()
    } else {
      verifyToken()
    }
  }, [email, token, isPec, tries])

  // Funzione che renderizza la corretta schermata di errore in base al codice ricevuto dal server
  const selectMessage = (code) => {
    switch (code) {
      case 'USER_ACTIVATION_5_1': {
        return <Message>{t('USER_ACTIVATION_5_1')}</Message>
      }
      case 'USER_ACTIVATION_5_2': {
        return <Message>{t('USER_ACTIVATION_5_2')}</Message>
      }
      case 'USER_ACTIVATION_5_3': {
        return <Message>{t('USER_ACTIVATION_5_3')}</Message>
      }
      case 'USER_ACTIVATION_7_1': {
        return <Message form={<RetryForm alertMessage={alertMessage} showAlert={showAlert} showTaxId action={verifyVatAndTaxId} />}>{t('USER_ACTIVATION_7_1')}</Message>
      }
      case 'USER_ACTIVATION_7_1_1': {
        return <Message>{t('USER_ACTIVATION_7_1_1')}</Message>
      }
      case 'USER_ACTIVATION_7_2': {
        return <Message form={<RetryForm alertMessage={alertMessage} showAlert={showAlert} showTaxId action={verifyVatAndTaxId} />}>{t('USER_ACTIVATION_7_2')}</Message>
      }
      case 'USER_ACTIVATION_7_2_1': {
        return <Message>{t('USER_ACTIVATION_7_2_1')}</Message>
      }
      case 'USER_ACTIVATION_7_3': {
        return <Message form={<RetryForm alertMessage={alertMessage} showAlert={showAlert} showTaxId action={verifyVatAndTaxId} />}>{t('USER_ACTIVATION_7_3')}</Message>
      }
      case 'USER_ACTIVATION_7_4': {
        return <Message>{t('USER_ACTIVATION_7_4')}</Message>
      }
      case 'USER_ACTIVATION_7_5': {
        return <Message>{t('USER_ACTIVATION_7_5')}</Message>
      }
      case 'USER_ACTIVATION_8_1': {
        return <Message form={<CompleteForm termsUrl={activeTerms || termsUrl} action={completeRegistration} companyExists={company.status} companyName={company.name} vatId={company.vatId} />}>{t('USER_ACTIVATION_8_1')}</Message>
      }
      case 'USER_ACTIVATION_9_1': {
        return <Message form={<CompleteForm termsUrl={activeTerms || termsUrl} companyExists={company.status} action={completeRegistration} companyName={company.name} vatId={company.vatId} />}>{t('USER_ACTIVATION_9_1')}</Message>
      }
      case 'USER_ACTIVATION_OK_7': {
        return <Message form={<CompleteForm termsUrl={activeTerms || termsUrl} companyExists={company.status} action={completeRegistration} companyName={company.name} vatId={company.vatId} />}>{t('USER_ACTIVATION_OK_7')}</Message>
      }
      case 'USER_ACTIVATION_OK_3': {
        return <Message form={<CompleteForm termsUrl={activeTerms || termsUrl} setPassword={false} companyExists={company.status} action={completeRegistrationWithCompanyDetails} companyName={company.name} vatId={company.vatId} />}>{t('USER_ACTIVATION_OK_7')}</Message>
      }
      case 'USER_ACTIVATION_7_1_2': {
        return <Message>{t('USER_ACTIVATION_7_1_2')}</Message>
      }
      case 'USER_ACTIVATION_7_2_2': {
        return <Message>{t('USER_ACTIVATION_7_2_2')}</Message>
      }
      case 'USER_ACTIVATION_10_1': {
        return <Message>{t('USER_ACTIVATION_10_1')}</Message>
      }
      case 'USER_ACTIVATION_10_2': {
        return <Message>{t('USER_ACTIVATION_10_2')}</Message>
      }
      case 'USER_ACTIVATION_10_3': {
        return <Message>{t('USER_ACTIVATION_10_3')}</Message>
      }
      case 'USER_ACTIVATION_10_4': {
        return <Message>{t('USER_ACTIVATION_10_4')}</Message>
      }
      case 'USER_ACTIVATION_11_1': {
        return <Message>{t('USER_ACTIVATION_11_1')}</Message>
      }
      case 'USER_ACTIVATION_11_2': {
        return <Message>{t('USER_ACTIVATION_11_2')}</Message>
      }
      case 'USER_ACTIVATION_11_3': {
        return <Message>{t('USER_ACTIVATION_11_3')}</Message>
      }
      case 'USER_ACTIVATION_11_4': {
        return <Message>{t('USER_ACTIVATION_11_4')}</Message>
      }
      case 'USER_ACTIVATION_11_5': {
        return <Message>{t('USER_ACTIVATION_11_5')}</Message>
      }
      case 'USER_ACTIVATION_OK_4': {
        return <Message>{t('USER_ACTIVATION_OK_4')}</Message>
      }
      case 'USER_ACTIVATION_OK_5': {
        return <Message>{t('USER_ACTIVATION_OK_5')}</Message>
      }
      case 'USER_ACTIVATION_OK_6': {
        return <Message action={() => history.push('/auth/login')} buttonText={t('buttons.login')}>{t('USER_ACTIVATION_OK_6')}</Message>
      }
      default: {
        // return <Message action={verifyEmail} buttonText='Verifica Email'>Completa la verifica della tua email per attivare il tuo account PlusAdvance</Message>
        return <Message>{t('defaultMessage')}</Message>
      }
    }
  }

  // funzione che completa la registrazione dell'utente
  const completeRegistration = async (values) => {
    setLoaderStatus(true)
    try {
      const { password, confirmPassword, companyName, userName: referentFullName, phoneNumber: referentPhoneNumber, email: referentEmail, acceptPrivacyPolicy, acceptMarketing } = values
      const body = referentFullName && referentPhoneNumber && referentEmail ? { onboardingTokenId: token, referentEmail, referentFullName, referentPhoneNumber, password, confirmPassword, companyName } : { onboardingTokenId: token, password, confirmPassword }
      body.acceptedTerms = activeTerms || termsUrl
      body.acceptPrivacyPolicy = acceptPrivacyPolicy
      body.acceptMarketing = acceptMarketing
      const registrationResponse = await api.postResource('users', { path: '/onboarding/password', body })
      const { code: successCode } = registrationResponse
      setLoaderStatus(false)
      setErrorCode(successCode)
    } catch (e) {
      const errCode = e && e.response && e.response.data && e.response.data.error && e.response.data.error.code
      setLoaderStatus(false)
      setErrorCode(errCode)
    }
  }
  const completeRegistrationWithCompanyDetails = async (values) => {
    setLoaderStatus(true)
    try {
      const { companyName, userName: referentFullName, phoneNumber: referentPhoneNumber, email: referentEmail } = values
      const body = referentFullName && referentPhoneNumber && referentEmail ? { onboardingTokenId: token, referentEmail, referentFullName, referentPhoneNumber, companyName } : { onboardingTokenId: token }
      body.acceptedTerms = activeTerms || termsUrl
      const registrationResponse = await api.postResource('users', { path: '/onboarding/companyDetails', body })
      const { code: successCode } = registrationResponse
      setLoaderStatus(false)
      setErrorCode(successCode)
    } catch (e) {
      const errCode = e && e.response && e.response.data && e.response.data.error && e.response.data.error.code
      setLoaderStatus(false)
      setErrorCode(errCode)
    }
  }

  // funzione che si occupa di reinviare la P.IVA al server per una nuova verifica con Telemaco
  /*  const verifyVatId = async (vatId) => {
    if (vatId) {
      setShowAlert(false)
      setLoaderStatus(true)
      const decodedEmail = decodeURIComponent(email)
      const body = { email: decodedEmail, onboardingTokenId: token, retries: tries, vatId }
      try {
        const activateResponse = await api.postResource('users', { path: '/onboarding/activation', body })
        const { company: responseCompany, code: successCode } = activateResponse
        if (responseCompany) {
          const { vatId: companyVatId, name, status } = responseCompany
          setCompany({ vatId: companyVatId, name, status })
        }
        setErrorCode(successCode)
        setLoaderStatus(false)
        setTries(0)
      } catch (e) {
        setTries(prevTries => parseInt(prevTries) + 1)
        const errCode = e && e.response && e.response.data && e.response.data.error && e.response.data.error.code

        errCode === 'USER_ACTIVATION_7_3' ? setAlertMessage('Partita IVA e codice fiscale non corrispondono!') : setAlertMessage('La partita IVA inserita non è valida!')
        setShowAlert(true)
        setLoaderStatus(false)
        setErrorCode(errCode)
      }
    }
  } */

  // funzione che si occupa di reinviare la P.IVA o il codice fiscale al server per una nuova verifica con Telemaco
  const verifyVatAndTaxId = async (vatId, taxId) => {
    if (vatId && taxId) {
      setShowAlert(false)
      setLoaderStatus(true)
      const decodedEmail = decodeURIComponent(email)
      const body = { email: decodedEmail, onboardingTokenId: token, retries: tries, vatId, taxId }
      try {
        const activateResponse = await api.postResource('users', { path: '/onboarding/activation', body })
        const { company: responseCompany, code: successCode } = activateResponse
        if (responseCompany) {
          const { vatId: companyVatId, name } = responseCompany
          setCompany({ vatId: companyVatId, name })
        }
        setErrorCode(successCode)
        setLoaderStatus(false)
        setTries(0)
      } catch (e) {
        setTries(prevTries => parseInt(prevTries) + 1)
        setShowAlert(true)
        const errCode = e && e.response && e.response.data && e.response.data.error && e.response.data.error.code
        errCode === 'USER_ACTIVATION_7_3' ? setAlertMessage(t('notifications.USER_ACTIVATION_7_3')) : setAlertMessage(t('notifications.generic'))
        setLoaderStatus(false)
        setErrorCode(errCode)
      }
    }
  }

  return (
    <Page
      className={classes.root}
      title={t('title')}
    >
      <Backdrop className={classes.backdrop} open={loaderStatus}>
        <CircularProgress color='primary' />
      </Backdrop>
      <Card className={classes.card}>
        <Box className={classes.cardHeader}>
          <Typography className={classes.cardHeaderTitle}>{t('cardTitle')}</Typography>
          <ChangeLanguageButton />
        </Box>
        <CardContent className={classes.content}>
          <img className={classes.icon} src='/images/logo.png' alt='plusadvance' />
          {selectMessage(errorCode)}
        </CardContent>
      </Card>
      <Box mt={8}>
        <Typography variant='subtitle2'>{t('copyright')}</Typography>
      </Box>
    </Page>
  )
}

export default ActivateUser
