import React, { useEffect, useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import clsx from 'clsx'
// import CryptoJS from 'crypto-js'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import {
  AppBar, colors, Hidden, IconButton,
  Toolbar
} from '@material-ui/core'
import InputIcon from '@material-ui/icons/Input'

import Button from '@material-ui/core/Button'
import ls from 'local-storage'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import configuration from '../../configuration'
import Badge from '@material-ui/core/Badge'
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined'
import ProfileIcon from '@material-ui/icons/PermIdentity'
import ProfilePopover from '../../components/ProfilePopover'
import NotificationsPopover from '../../components/NotificationsPopover'
import { clearManageUsers, clearMarketplace, logout } from '../../actions'
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    background: 'linear-gradient(90deg, #d62929, #4b1e82)'
  },
  flexGrow: {
    flexGrow: 1
  },
  loginButton: {
    marginLeft: theme.spacing(1)
  },
  loginIcon: {
    marginRight: theme.spacing(1)
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  profileMenuButton: {
    marginLeft: theme.spacing(1)
  },
  whiteIcon: {
    color: 'white'
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logo: {
    height: '45px',
    width: 'auto'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // width: 255,
    height: 64
    // marginLeft: -24,
    // backgroundColor: '#fafafa',
    // borderRight: `1px solid ${theme.palette.divider}`,
    // borderBottom: `1px solid ${theme.palette.divider}`
  }
}))

function TopBar ({
  onOpenNavBarMobile,
  onOpenNavBarDesktop,
  onCloseNavBarDesktop,
  isNavBarDesktopOpen,
  className,
  ...rest
}) {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const session = useSelector(state => state.session)
  const { currentCompany } = session
  const [openMenu, setOpenMenu] = useState(false)
  const [openNotifications, setOpenNotifications] = useState(false)
  const notificationsRef = useRef(null)
  const menuRef = useRef(null)

  const [notifications] = useState([])
  const handleNotificationsOpen = () => {
    setOpenNotifications(true)
  }
  const handleNotificationsClose = () => {
    setOpenNotifications(false)
  }
  const handleMenuOpen = () => {
    setOpenMenu(true)
  }

  const handleMenuClose = () => {
    setOpenMenu(false)
  }
  const user = ls.get('plsadv_user')
  useEffect(() => {
    if (user && user.email && currentCompany && currentCompany.name) {
      const tags = []
      const {
        isBuyer,
        isSupplier,
        name
      } = currentCompany
      const {
        email
      } = user

      if (currentCompany.isBuyer) {
        tags.push('buyer')
      }
      if (currentCompany.isSupplier) {
        tags.push('supplier')
      }

      try {
        if (window.fcWidget) {
          console.log('Widget Loaded')
          window.fcWidget.destroy()
          window.fcWidget.on('widget:destroyed', function (resp) {
            console.log('User Created')
            window.fcWidget.init({
              host: 'https://wchat.eu.freshchat.com',
              open: false,
              locale: 'it',
              token: configuration.freshChatKey,
              externalId: user.uuid,
              email,
              firstName: name,
              meta: {
                isBuyer,
                isSupplier,
                vatId: currentCompany.vatId
              },
              faqTags: {
                tags,
                filterType: 'category'
              }
            })
          })
        }
      } catch (e) {
        console.log('Freshchat err', e)
      }
    }
    // eslint-disable-next-line
  }, [user, currentCompany])
  const handleLogout = () => {
    ls.clear()
    dispatch(clearManageUsers())
    dispatch(clearMarketplace())
    dispatch(logout())
    history.push('/auth/login')
  }
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      // color='primary'
    >
      <Toolbar>
        <div className={classes.logoContainer}>
          {
            user && <Hidden mdUp>
              <IconButton
                className={classes.menuButton}
                onClick={onOpenNavBarMobile}
              >
                <MenuIcon className={classes.whiteIcon} />
              </IconButton>
            </Hidden>
          }
          {
            user && <Hidden smDown>
              {!isNavBarDesktopOpen ? (
                <IconButton
                  className={classes.menuButton}
                  color='primary'
                  onClick={onOpenNavBarDesktop}
                >
                  <MenuIcon className={classes.whiteIcon} />
                </IconButton>
              ) : null}
            </Hidden>
          }
          <RouterLink to='/'>
            <img
              className={classes.logo}
              alt='Logo'
              src='/images/plusadvance-logo-white.png'
            />
          </RouterLink>
          <Hidden smDown>
            {isNavBarDesktopOpen ? (
              <IconButton
                color='primary'
                onClick={onCloseNavBarDesktop}
              >
                <ChevronLeftIcon className={classes.whiteIcon} />
              </IconButton>
            ) : null}
          </Hidden>
        </div>
        <div className={classes.flexGrow} />
        {
          user && user.email
            ? <>
              <IconButton
                className={classes.notificationsButton}
                color='inherit'
                onClick={handleNotificationsOpen}
                ref={notificationsRef}
              >
                <Badge
                  badgeContent={notifications.length}
                  classes={{ badge: classes.notificationsBadge }}
                  variant='dot'
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                className={classes.profileMenuButton}
                color='inherit'
                onClick={handleMenuOpen}
                ref={menuRef}
              >
                <ProfileIcon />
              </IconButton>
            </>
            : <Button
              className={classes.loginButton}
              color='inherit'
              onClick={() => history.push('/auth/login')}
            >
              <InputIcon className={classes.loginIcon} />
                Login
              </Button>
        }
      </Toolbar>
      <ProfilePopover
        anchorEl={menuRef.current}
        onClose={handleMenuClose}
        handleLogout={handleLogout}
        open={openMenu}
      />
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
}

export default TopBar
